import startCase from 'lodash/startCase';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Colorwash from '../components/Colorwash';
import Footer from '../components/footer/FooterDynamic';
import Header from '../components/header/Header';
import HeaderTags from '../components/header/HeaderTags';
import { HEADER_FOOTER_LAYOUT } from '../config/constants/layout-types';
import {
  HYPER_LOCATION_META_TITLE,
  HYPER_LOCATION_META_DESCRIPTION,
} from '../config/setup/setup';
import { getLocationShortName } from '../helpers/location';
import { getPageTitle, getMetaDescription } from '../helpers/metadata';
import { parseWowcherPath } from '../helpers/url';
import { getClientSideLayoutEssentials } from '../redux/actions/clientRender';

const HeaderFooterLayout = ({
  children = null,
  title = '',
  description = '',
  countdown,
  titletype = '',
  hasColorwash = true,
  getMenu = false,
  robotsMeta = 'INDEX, FOLLOW',
}) => {
  const [
    facetedNavigation,
    location,
    navigation,
    locations,
  ] = useSelector((state) => [
    state.deals.facetedNavigation,
    state.locations.location,
    state.navigation.list || [],
    state.locations.locations || [],
  ]);
  const user = useSelector((state) => state.user);
  const router = useRouter();
  const shortName = getLocationShortName(location);
  const { details } = parseWowcherPath(router.asPath);
  let titleAux;
  const dispatch = useDispatch();

  // TODO: created different ways to get the title dinamically
  // We get the title from the facetedNavigation if titled is empty
  const generatedTitle =
    titletype && titletype.length
      ? getPageTitle({
          aspath: router.asPath,
          facetedNavigation,
          locationnameAux: location.name,
          locations,
          navigation,
          pageTitle: null,
          query: null,
          shortnameAux: location.shortName,
          titletype: null,
        })
      : '';
  let generatedDescription =
    description && description.length
      ? description
      : getMetaDescription(
          facetedNavigation,
          router.asPath,
          location.shortName,
          location.name,
          navigation,
          locations,
        );
  // if title is empty titletype let us know how to get the title
  titleAux = title && title.length ? title : generatedTitle;
  if (typeof window !== 'undefined' && details?.isHyperlocal) {
    const isHub = router.asPath.includes('hub');
    if (isHub) {
      titleAux =
        'Deals Near You - Local Restaurants, Spas & Things To Do - Wowcher';
      generatedDescription =
        "Save up to 80% on local deals and activities near you. Whether you're heading out on a day trip or want to find something in your local area to do, we have discounts for the best restaurants, zoos, theme parks and more!";
    } else {
      let area = '';
      if (router.query?.path) {
        area = router.query.path.replace(`-`, ' ');
      } else if (router.query?.slug) {
        area = router.query.slug[router.query.slug.length - 1];
      }
      titleAux = HYPER_LOCATION_META_TITLE(startCase(area));
      generatedDescription = HYPER_LOCATION_META_DESCRIPTION(startCase(area));
    }
  }

  useEffect(() => {
    dispatch(getClientSideLayoutEssentials(user, HEADER_FOOTER_LAYOUT));
  }, [dispatch, user.isAuthenticated, router.asPath]);

  return (
    <>
      <HeaderTags
        description={generatedDescription}
        robotsMeta={robotsMeta}
        title={titleAux}
      />
      {hasColorwash && <Colorwash />}
      <Header getMenu={getMenu} path={router.asPath} />
      <div className="app" id="main-view">
        {children}
      </div>
      <Footer
        isSecurePage={false}
        locationShortName={shortName}
        site={process.env.NEXT_PUBLIC_SITE}
      />
    </>
  );
};

HeaderFooterLayout.propTypes = {
  children: PropTypes.node,
  countdown: PropTypes.bool,
  description: PropTypes.string,
  getMenu: PropTypes.bool,
  hasColorwash: PropTypes.bool,
  title: PropTypes.string,
  titletype: PropTypes.string,
};

export default HeaderFooterLayout;
