import isEmpty from 'lodash/isEmpty';
import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { DEALS } from '../../config/constants/action-types';
import { DESKTOP_PAGE_SIZE } from '../../config/links/links';
import { getDealApiPath } from '../../helpers/api';
import ErrorTrackingService from '../../helpers/errorTrackingService';
import httpCommonHeaders from '../../helpers/httpCommonHeaders';
import { parseWowcherPath, stripOrigin } from '../../helpers/url';
import {
  getDealPreviewData,
  getFacetedNavigationForTravelHomepage,
} from '../../services/deals';
import { FEATURED_DEALS_LOADED } from '../reducers/deals';
import {
  setDeals,
  setOnlyMainDeal,
  noMoreDeals,
  setIsDealPreview,
} from './setDeals';

export const getDeals = ({
  dealId,
  emailDealLocation,
  isCategoryPage = false,
  isEmailDeal,
  isPreview = false,
  onlyMainDeal = false,
  page = 0,
  pageNumber = null,
  pageSize = DESKTOP_PAGE_SIZE,
  path,
  secondaryDealsCount, // secondaryDeals count is used for redux of splicing deals to render on deal page
  sideDealsLocal,
  ssr = false,
}) => async (dispatch) => {
  if (isEmpty(path)) {
    throw new Error('Url Path is required to use getDeals');
  }

  const { details } = parseWowcherPath(path);

  const url = getDealApiPath({
    currentPagePath: path,
    dealIdOverride: dealId,
    emailDealLocation,
    ignoreFilters: true,
    isCategoryPage,
    isEmailDeal,
    pageNumber,
    pageSize,
    sideDealsLocal,
  });

  try {
    const response = await axios(url, {
      headers: httpCommonHeaders(),
      method: 'GET',
      // widthCredentials is true if ssr is false
      withCredentials: !ssr,
    });
    if (!response.data) throw new Error('Empty response from server');

    const { data } = response;

    const deals = data.deals || [];

    let secondaryDealsArray = [];
    // TODO : refactor how we get secondary deals to be added into redux layer
    // TODO: There are better solutions of adding secondary deals as we are duplicating this when it is not required
    if (data.mainDeal && Object.keys(data.mainDeal).length !== 0) {
      secondaryDealsArray = deals.filter((item, index) => {
        return index >= 0 && index < secondaryDealsCount;
      });
    }

    if (onlyMainDeal) {
      dispatch(
        setOnlyMainDeal(
          data.mainDeal ? data.mainDeal : {},
          secondaryDealsArray,
        ),
      );
    } else {
      const canonicalUrl = data.canonicalUrl
        ? `${data.canonicalUrl}${page > 0 ? '?page=' + (page + 1) : ''}`
        : window.location.href.split('?')[0];

      let facetedNavigation = null;
      if (details?.isTravelHomepage) {
        facetedNavigation = await getFacetedNavigationForTravelHomepage();
      }

      dispatch(
        setDeals({
          canonicalUrl,
          deals,
          evergreenUrl: stripOrigin(data.evergreenUrl ?? null),
          facetedNavigation: facetedNavigation ?? data.facetedNavigation ?? {},
          isPreview,
          mainDeal: data.mainDeal,
          redirectUrl: data.redirectUrl ?? null,
          searchResult: data.searchResult ?? null,
          secondaryDealsArray,
          ssr,
        }),
      );

      noMoreDeals({ deals, dispatch, page });
    }

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);
  }
};

// deals method for recommendations, new products and recently reviewed
export const getDealsData = (payloadKey, url, type, hasLoaded) => async (
  dispatch,
) => {
  try {
    const response = await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url,
      withCredentials: true,
    });
    const deals = response.data.deals;
    dispatch({
      [payloadKey]: deals,
      type,
    });

    return dispatch({
      featuredDealsLoaded: hasLoaded
        ? FEATURED_DEALS_LOADED.COMPLETE
        : FEATURED_DEALS_LOADED.UNCOMPLETE,
      type: DEALS.SET_ALL_FEATURED_DEALS_LOADED,
    });
  } catch (error) {
    console.log(
      `error [AXIOS REQUEST: getDealsData type: ${payloadKey}] => ${error}`,
    );
    if (type === DEALS.SET_RECOMMENDED_DEALS) {
      console.warn(error);
      ErrorTrackingService.logError(error);
    }

    return false;
  }
};

export const getDealPreview = (uid) => async (dispatch) => {
  try {
    const data = await getDealPreviewData(uid);
    // only setting main deal for deal preview
    dispatch(setOnlyMainDeal(data ? data : {}));
    dispatch(setIsDealPreview(true));
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);
  }
};

export const setOnlySSR = (ssr) => (dispatch) => {
  dispatch({ ssr, type: DEALS.SET_ONLY_SSR });
};
