// eslint-disable-next-line import/no-namespace
import * as dayjs from 'dayjs';
import padStart from 'lodash/padStart';

export const getCountDown = () => {
  const toDate = new Date();
  const tomorrow = new Date();

  tomorrow.setHours(24, 0, 0, 0);

  let diffMS = tomorrow.valueOf() / 1_000 - toDate.valueOf() / 1_000;
  const diffHr = Math.floor(diffMS / 3_600);
  diffMS -= diffHr * 3_600;
  const diffMi = Math.floor(diffMS / 60);
  diffMS -= diffMi * 60;

  const diffS = Math.floor(diffMS);
  let result = diffHr < 10 ? '0' + diffHr : diffHr;
  result += ':' + (diffMi < 10 ? '0' + diffMi : diffMi);
  result += ':' + (diffS < 10 ? '0' + diffS : diffS);

  return `00:${result}`;
};

/**
 * Convert seconds into a min:seconds string
 */
export function secondsToMinutesAndSeconds(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return `${mins.toString().padStart(2, '0')}:${secs
    .toString()
    .padStart(2, '0')}`;
}

const FIVE_MINUTES_IN_SECONDS = 300;

/**
 * Get a Date 5 min from now
 */
export const getFiveMinInFuture = () => {
  if (!dayjs || typeof dayjs !== 'function') return new Date();
  const now = dayjs();

  return now.add(FIVE_MINUTES_IN_SECONDS, 'second').format();
};

export const getCountDownMinInFuture = (countdown) => {
  if (!dayjs || typeof dayjs !== 'function') return new Date();
  const now = dayjs();

  return now.add(countdown, 'minutes').format();
};
/**
 * Diff futureDate to now
 */
export const getSecsLeft = (futureDateString) => {
  if (!futureDateString || !dayjs || typeof dayjs !== 'function')
    return FIVE_MINUTES_IN_SECONDS;
  const dateNow = dayjs();
  const futureDate = dayjs(futureDateString);
  const timeLeftInSec = futureDate.diff(dateNow, 'second');

  return timeLeftInSec >= 0 ? timeLeftInSec : 0;
};

// get a timer object from a timestamp
export const getTimerObject = (futureUnixDate) => {
  // must have a value
  if (!futureUnixDate) {
    return null;
  }

  // accept timestamp in second or millisecond
  const isUnixMilliSeconds = futureUnixDate.toString().length === 13;
  // make sure a number
  const unixDate = Number(futureUnixDate);

  // must be be a number
  if (typeof unixDate !== 'number') {
    return null;
  }

  // ms vs s, get the future date object
  const dateFuture = isUnixMilliSeconds
    ? dayjs(unixDate)
    : dayjs.unix(unixDate);

  // get now date object
  const dateNow = dayjs();

  // must be in the future to countdown
  if (dateFuture < dateNow) {
    return null;
  }

  let timeSec = dateFuture.diff(dateNow, 'second');

  const days = Math.floor(timeSec / 86_400);
  timeSec -= days * 86_400;
  const hours = Math.floor(timeSec / 3_600) % 24;
  timeSec -= hours * 3_600;
  const minutes = Math.floor(timeSec / 60) % 60;
  timeSec -= minutes * 60;
  const seconds = timeSec % 60;

  const returnDays = padStart(days, 2, 0);
  const returnHours = padStart(hours, 2, 0);
  const returnMinutes = padStart(minutes, 2, 0);
  const returnSeconds = padStart(Number.parseInt(seconds, 10), 2, 0);

  return {
    days: returnDays,
    hours: returnHours,
    minutes: returnMinutes,
    seconds: returnSeconds,
  };
};
