/* eslint-disable sonarjs/no-duplicate-string */
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const ENV = process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod';
const BRAND_WOW = process.env.NEXT_PUBLIC_SITE || 'wowcher';
const BRAND_LSUK = process.env.NEXT_PUBLIC_SITE || 'livingsocial';
const BRAND_LSIE = process.env.NEXT_PUBLIC_SITE || 'livingsocialie';
const BRAND_WOWCHERIE = process.env.NEXT_PUBLIC_SITE || 'wowcherie';

// REDIRECT TO ANGULAR URLS false : true
const USE_ANGULAR_URL_BUY_BUTTON = false; // Redirect to Angular Buy/Gift Buttons
const isKlarnaEnabled = JSON.parse(
  process.env.NEXT_PUBLIC_KLARNA_ENABLED === 'true',
);

export const DEFAULT_PUBLIC_API = 'https://public-api.wowcher.co.uk';
export const DEFAULT_PUBLIC_API_TEST = 'https://public-api05.devwowcher.co.uk';

export const LOGIN_URL = 'login';
export const REGISTER_URL = 'register';
export const WISHLIST_URL = 'wishlist';

export const MAP_VIEW_URL = '/map-view';

export const DEFAULT_SUGGGEST_API = {
  dev: {
    livingsocial: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsuk`,
    livingsocialie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsie`,
    wowcher: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
    wowcherie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
  },
  nxt: {
    livingsocial: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsuk`,
    livingsocialie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsie`,
    wowcher: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
    wowcherie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
  },
  prod: {
    livingsocial: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsuk`,
    livingsocialie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestlsie`,
    wowcher: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
    wowcherie: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
  },
};

export const GIFTFINDER_CONFIG = {
  dev: {
    livingsocial:
      'https://s3.eu-west-1.amazonaws.com/static10.devwowcher.co.uk/binaries/gift-finder-ls_v1.json',
    livingsocialie:
      'https://s3.eu-west-1.amazonaws.com/static10.devwowcher.co.uk/binaries/gift-finder-ls_v1.json',
    wowcher:
      'https://s3.eu-west-1.amazonaws.com/static10.devwowcher.co.uk/binaries/gift-finder-wow_v1.json',
    wowcherie:
      'https://s3.eu-west-1.amazonaws.com/static10.devwowcher.co.uk/binaries/gift-finder-wow_v1.json',
  },
  nxt: {
    livingsocial:
      'https://s3.eu-west-1.amazonaws.com/static01.nxtwowcher.co.uk/binaries/gift-finder-ls_v1.json',
    livingsocialie:
      'https://s3.eu-west-1.amazonaws.com/static01.nxtwowcher.co.uk/binaries/gift-finder-ls_v1.json',
    wowcher:
      'https://s3.eu-west-1.amazonaws.com/static01.nxtwowcher.co.uk/binaries/gift-finder-wow_v1.json',
    wowcherie:
      'https://s3.eu-west-1.amazonaws.com/static01.nxtwowcher.co.uk/binaries/gift-finder-wow_v1.json',
  },
  prod: {
    livingsocial:
      'https://s3.eu-west-1.amazonaws.com/static.wowcher.co.uk/binaries/gift-finder-ls_v1.json',
    livingsocialie:
      'https://s3.eu-west-1.amazonaws.com/static.wowcher.co.uk/binaries/gift-finder-ls_v1.json',
    wowcher:
      'https://s3.eu-west-1.amazonaws.com/static.wowcher.co.uk/binaries/gift-finder-wow_v1.json',
    wowcherie:
      'https://s3.eu-west-1.amazonaws.com/static.wowcher.co.uk/binaries/gift-finder-wow_v1.json',
  },
};

export const LINKS_GLOBAL_WOWCHER = {
  dev: {
    livingsocial:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL ||
      'https://www09.devlivingsocial.co.uk',
    livingsocialie:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL ||
      'https://www09.devlivingsocialie.net',
    wowcher:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www09.devwowcher.co.uk',
    wowcherie:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www09.devwowcherie.net',
  },
  nxt: {
    livingsocial:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL ||
      'https://www01.nxtlivingsocial.co.uk',
    livingsocialie:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL ||
      'https://www01.nxtlivingsocialie.net',
    wowcher:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www01.nxtwowcher.co.uk',
    wowcherie:
      process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www01.nxtwowcherie.net',
  },
  prod: {
    livingsocial: 'https://www.livingsocial.co.uk',
    livingsocialie: 'https://www.livingsocial.ie',
    wowcher: 'https://www.wowcher.co.uk',
    wowcherie: 'https://www.wowcherie.net',
  },
};

const SPLUNK_BASE_URL_DEV =
  'https://internal-splunk-dev.wowcher.co.uk/services/collector/event';
const SPLUNK_BASE_URL_PROD =
  'https://internal-splunk.wowcher.co.uk/services/collector/raw';

export const LINKS_GLOBAL_SPLUNK = {
  dev: SPLUNK_BASE_URL_DEV,
  nxt: SPLUNK_BASE_URL_DEV,
  prod: SPLUNK_BASE_URL_PROD,
};

export const LINKS_GLOBAL_SECURE = {
  dev: {
    livingsocial: 'https://secure05.devlivingsocial.co.uk',
    livingsocialie: 'https://secure05.devlivingsocialie.net',
    wowcher: 'https://secure05.devwowcher.co.uk',
    wowcherie: 'https://secure05.devwowcher.co.uk',
  },
  nxt: {
    livingsocial: 'https://secure01.nxtlivingsocial.co.uk',
    livingsocialie: 'https://secure01.nxtlivingsocialie.net',
    wowcher: 'https://secure01.nxtwowcher.co.uk',
    wowcherie: 'https://secure01.nxtwowcher.co.uk',
  },
  prod: {
    livingsocial: 'https://www.livingsocial.co.uk',
    livingsocialie: 'https://www.livingsocial.ie',
    wowcher: 'https://www.wowcher.co.uk',
    wowcherie: 'https://www.wowcher.co.uk',
  },
};

export const ANGULAR_HOME_URL = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}`;

export const LINK_HOME = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}`;

export const LINK_FORGOT_PASSWORD = `/forgot-password`;

export const MARKETING_CLAIMS_DEF_LINK = {
  livingsocial: 'https://www.livingsocial.co.uk/page/marketingdefinitions.html',
  livingsocialie: 'https://www.livingsocial.ie/page/marketingdefinitions.html',
  wowcher: 'https://www.wowcher.co.uk/page/marketingdefinitions.html',
  wowcherie: 'https://www.wowcher.co.uk/page/marketingdefinitions.html',
};

export const LINK_LOGGED_USER = LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW];
export const LINK_SEARCH_PAGE = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/search`;
export const LINK_NOT_LOGGED_USER = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/${LOGIN_URL}`; // Dont need to be the same as login
export const LINK_LOGIN = `/${LOGIN_URL}`;
export const LINK_WISHLIST = `/${WISHLIST_URL}`;
export const LINK_MY_VOUCHERS = `/myaccount/vouchers`;
export const LINK_MY_VOUCHERS_DELETE = `/myaccount/delete`;
export const LINK_REGISTER = `/register`;
// absolute link to fix occasional payment bug going from checkout to second-checkout: PRD-2708
export const LINK_SECOND_CHECKOUT = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/order-addon`;
export const LINK_CHECKOUT = `/checkout`;
export const LINK_CHECKOUT_RELATIVE = `/checkout`;
export const LINK_MYACCOUNT_PASSWORD = `/myaccount/password`;
export const LINK_SECOND_CHECKOUT_BENEFITS = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/second-checkout/benefits`;
export const LINK_CHECKOUT_BUTTON = `${
  (USE_ANGULAR_URL_BUY_BUTTON && LINKS_GLOBAL_SECURE[ENV][BRAND_WOW]) || ''
}/checkout`;
export const LINK_LIVINGSOCIAL_IE = {
  dev: 'https://www05.devlivingsocialie.net',
  nxt: 'https://www01.nxtlivingsocialie.net',
  prod: 'https://www.livingsocial.ie',
};
export const LINK_LIVINGSOCIAL = {
  dev: 'https://www05.devlivingsocial.co.uk',
  nxt: 'https://www01.nxtlivingsocial.co.uk',
  prod: 'https://www.livingsocial.co.uk',
};

export const LINK_WOWCHER_IE = {
  dev: 'https://www09.devwowcherie.net',
  nxt: 'https://www01.nxtwowcherie.net',
  prod: 'https://www.wowcher.ie',
};
export const LINK_WOWCHER = {
  dev: 'https://www09.devwowcher.co.uk',
  nxt: 'https://www01.nxtwowcher.co.uk',
  prod: 'https://www.wowcher.co.uk',
};

export const LINK_MAIN_HOME = `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}`;

export const LINK_GIFT_FINDER = (location) => `/deal/${location}/gift-finder`;

export const RESET_PASSWORD = '/request-password-reset';

// APP STORES
export const LINK_APPLE_APP_STORE = {
  livingsocial: 'https://livingsocial.onelink.me/lhhM/ynmrr0iv',
  livingsocialie: 'https://livingsocial.onelink.me/lhhM/ynmrr0iv',
  wowcher: 'https://wowcher.onelink.me/j47e/IOSwebsitelozenge',
  wowcherie: 'https://wowcher.onelink.me/j47e/IOSwebsitelozenge', // TODO: update this with correct wowcher ie value
};
export const LINK_GOOGLE_PLAY_STORE = {
  livingsocial: 'https://livingsocial.onelink.me/lhhM/ynmrr0iv',
  livingsocialie: 'https://livingsocial.onelink.me/lhhM/ynmrr0iv',
  wowcher: 'https://wowcher.onelink.me/j47e/ANDwebsitelozenge',
  wowcherie: 'https://wowcher.onelink.me/j47e/ANDwebsitelozenge', // TODO: update this with correct wowcher ie value
};

// APP BANNER
export const APP_BANNER_DEEPLINK_APPLE = {
  livingsocial: 'https://living-social.app.link/Z2w99F0HI4',
  livingsocialie: 'https://living-social.app.link/Z2w99F0HI4',
  wowcher: 'https://wowcher.app.link/mIzxxPXGI4',
  wowcherie: 'https://wowcher.app.link/mIzxxPXGI4', // TODO: update this with correct wowcher ie value
};
export const APP_BANNER_DEEPLINK_ANDROID = {
  livingsocial: 'https://living-social.app.link/Z2w99F0HI4',
  livingsocialie: 'https://living-social.app.link/Z2w99F0HI4',
  wowcher: 'https://wowcher.app.link/mIzxxPXGI4',
  wowcherie: 'https://wowcher.app.link/mIzxxPXGI4', // TODO: update this with correct wowcher ie value
};

export const LINK_CAREERS = 'https://careers.wowcher.co.uk/careers.html';

// TODO: update this with correct wowcher ie value
export const SOCIAL_MEDIA_SHARE_LINKS = {
  livingsocial: [
    {
      ariaLabel: 'Facebook Page',
      backgroundColor: '#0052a3',
      href: 'https://www.facebook.com/LivingSocialUK',
      icon: faFacebookF,
    },
    {
      ariaLabel: 'Twitter Page',
      backgroundColor: '#4099ff',
      href: 'https://twitter.com/LivingSocialUK',
      icon: faTwitter,
    },
    {
      ariaLabel: 'Instagram Page',
      backgroundColor: '#125688',
      href: 'https://www.instagram.com/livingsocialuk/',
      icon: faInstagram,
    },
  ],
  livingsocialie: [
    {
      ariaLabel: 'Facebook Page',
      backgroundColor: '#0052a3',
      href: 'https://www.facebook.com/LivingSocialIE',
      icon: faFacebookF,
    },
    {
      ariaLabel: 'Twitter Page',
      backgroundColor: '#4099ff',
      href: 'https://twitter.com/LivingSocialIE',
      icon: faTwitter,
    },
    {
      ariaLabel: 'Instagram Page',
      backgroundColor: '#125688',
      href: 'https://www.instagram.com/livingsocial.ie/',
      icon: faInstagram,
    },
  ],
  wowcher: [
    {
      ariaLabel: 'Facebook Page',
      backgroundColor: '#0052a3',
      href: 'https://www.facebook.com/wowcher.co.uk',
      icon: faFacebookF,
    },
    {
      ariaLabel: 'Twitter Page',
      backgroundColor: '#4099ff',
      href: 'https://twitter.com/wowcher',
      icon: faTwitter,
    },
    {
      ariaLabel: 'Instagram Page',
      backgroundColor: '#125688',
      href: 'https://www.instagram.com/wowcher',
      icon: faInstagram,
    },
  ],
  wowcherie: [
    {
      ariaLabel: 'Facebook Page',
      backgroundColor: '#0052a3',
      href: 'https://www.facebook.com/wowcher.co.uk',
      icon: faFacebookF,
    },
    {
      ariaLabel: 'Twitter Page',
      backgroundColor: '#4099ff',
      href: 'https://twitter.com/wowcher',
      icon: faTwitter,
    },
    {
      ariaLabel: 'Instagram Page',
      backgroundColor: '#125688',
      href: 'https://www.instagram.com/wowcher',
      icon: faInstagram,
    },
  ],
};

// TODO: update this with correct wowcher ie value
export const FOOTER_LEGAL_LINKS = {
  livingsocial: [
    {
      href: 'https://www.livingsocial.co.uk/page/e-commerceterms#contract',
      text: 'Billing Terms & Conditions',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/faqs.html',
      text: 'Refunds & Returns',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/termsofuse',
      text: 'Terms of use',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/privacypolicy',
      text: 'Privacy & Cookies Policy',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/e-commerceterms',
      text: 'E-Commerce Terms',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/internetsafety',
      text: 'Internet Safety',
    },
    {
      href:
        'https://www.livingsocial.co.uk/page/uk-modern-slavery-act-transparency-statement',
      text: 'UK modern slavery act',
    },
    {
      href: 'https://www.livingsocial.co.uk/page/takedown',
      text: 'Takedown Policy',
    },
    isKlarnaEnabled && {
      href: 'https://www.livingsocial.co.uk/page/klarna-faqs',
      text: 'Klarna FAQs',
    },
  ],
  livingsocialie: [
    {
      href: 'https://www.livingsocial.ie/page/e-commerceterms-ie#contract',
      text: 'Billing Terms & Conditions',
    },
    {
      href: 'https://www.livingsocial.ie/page/faqs-ie.html',
      text: 'Refunds & Returns',
    },
    {
      href: 'https://www.livingsocial.ie/page/termsofuse-ie',
      text: 'Terms of use',
    },
    {
      href: 'https://www.livingsocial.ie/page/privacypolicy-ie',
      text: 'Privacy & Cookies Policy',
    },
    {
      href: 'https://www.livingsocial.ie/page/e-commerceterms-ie',
      text: 'E-Commerce Terms',
    },
    {
      href: 'https://www.livingsocial.ie/page/internetsafety',
      text: 'Internet Safety',
    },
    {
      href:
        'https://www.livingsocial.ie/page/uk-modern-slavery-act-transparency-statement',
      text: 'UK modern slavery act',
    },
    {
      href: 'https://www.livingsocial.ie/page/takedown',
      text: 'Takedown Policy',
    },
    isKlarnaEnabled && {
      href: 'https://www.livingsocial.ie/page/klarna-faqs',
      text: 'Klarna FAQs',
    },
  ],
  wowcher: [
    {
      href: 'https://www.wowcher.co.uk/page/e-commerceterms#contract',
      text: 'Billing Terms & Conditions',
    },
    {
      href: 'https://help.wowcher.co.uk/knowledgebase/s/article/Refunds',
      text: 'Refunds & Returns',
    },
    { href: 'https://www.wowcher.co.uk/page/termsofuse', text: 'Terms of use' },
    {
      href: 'https://www.wowcher.co.uk/page/privacypolicy',
      text: 'Privacy & Cookies Policy',
    },
    {
      href: 'https://www.wowcher.co.uk/page/e-commerceterms',
      text: 'E-Commerce Terms',
    },
    {
      href: 'https://www.wowcher.co.uk/page/internetsafety',
      text: 'Internet Safety',
    },
    {
      href:
        'https://www.wowcher.co.uk/page/uk-modern-slavery-act-transparency-statement',
      text: 'UK modern slavery act',
    },
    {
      href: 'https://www.wowcher.co.uk/page/takedown',
      text: 'Takedown Policy',
    },
    isKlarnaEnabled && {
      href: 'https://www.wowcher.co.uk/page/klarna-faqs',
      text: 'Klarna FAQs',
    },
  ],
  wowcherie: [
    {
      href: 'https://www.wowcher.co.uk/page/e-commerceterms#contract',
      text: 'Billing Terms & Conditions',
    },
    {
      href: 'https://help.wowcher.co.uk/knowledgebase/s/article/Refunds',
      text: 'Refunds & Returns',
    },
    { href: 'https://www.wowcher.co.uk/page/termsofuse', text: 'Terms of use' },
    {
      href: 'https://www.wowcher.co.uk/page/privacypolicy',
      text: 'Privacy & Cookies Policy',
    },
    {
      href: 'https://www.wowcher.co.uk/page/e-commerceterms',
      text: 'E-Commerce Terms',
    },
    {
      href: 'https://www.wowcher.co.uk/page/internetsafety',
      text: 'Internet Safety',
    },
    {
      href:
        'https://www.wowcher.co.uk/page/uk-modern-slavery-act-transparency-statement',
      text: 'UK modern slavery act',
    },
    {
      href: 'https://www.wowcher.co.uk/page/takedown',
      text: 'Takedown Policy',
    },
    isKlarnaEnabled && {
      href: 'https://www.wowcher.co.uk/page/klarna-faqs',
      text: 'Klarna FAQs',
    },
  ],
};

// TODO: update this with correct wowcher ie value
export const FOOTER_DEALS_LINKS = {
  livingsocial: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/deals/shop`,
      text: 'Shop',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/deals/escapes`,
      text: 'Escapes',
    },
  ],
  livingsocialie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/deals/shop`,
      text: 'Shop',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/deals/escapes`,
      text: 'Escapes',
    },
    // NOTE: Uncomment for use to complete future ticket's requirements
    // {
    //   text: 'Discount Codes',
    //   href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/discount-codes/hub`,
    // },
  ],
  wowcher: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/deals/shop`,
      text: 'Shopping',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/guides/holiday-deals`,
      text: 'Holiday Deals',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/guides/hub`,
      text: 'Guides',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/near-me/hub`,
      text: 'Near Me',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/guides/black-friday-deals`,
      text: 'Black Friday Deals',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/discountcodes`,
      text: 'Discount Codes',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/editorial-team`,
      text: 'Meet Our Writers',
    },
  ],
  wowcherie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/deals/shop`,
      text: 'Shopping',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/guides/holiday-deals`,
      text: 'Holiday Deals',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/guides/hub`,
      text: 'Guides',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/near-me/hub`,
      text: 'Near Me',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/guides/black-friday-deals`,
      text: 'Black Friday Deals',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/discountcodes`,
      text: 'Discount Codes',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/editorial-team`,
      text: 'Meet Our Writers',
    },
  ],
};

export const FOOTER_ABOUT_US = 'About Us';
export const FOOTER_MERCHANT_FAQ = 'Merchant FAQs';
export const FOOTER_AFFILIATE_FAQ = 'Affiliate FAQs';
export const FOOTER_PRESS = 'Press FAQs';

// TODO: update this with correct wowcher ie value
export const FOOTER_COMPANY_LINKS = {
  livingsocial: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/aboutus`,
      text: FOOTER_ABOUT_US,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/merchantfaqs`,
      text: FOOTER_MERCHANT_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/affiliates-livingsocial`,
      text: FOOTER_AFFILIATE_FAQ,
    },
  ],
  livingsocialie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/aboutus`,
      text: FOOTER_ABOUT_US,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/merchantfaqs`,
      text: FOOTER_MERCHANT_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/livingsocial-ie-affiliates`,
      text: FOOTER_AFFILIATE_FAQ,
    },
  ],
  wowcher: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/aboutus`,
      text: FOOTER_ABOUT_US,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/workwithus`,
      text: FOOTER_MERCHANT_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/guides/wowcher-affiliates`,
      text: FOOTER_AFFILIATE_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/guides/press-resources`,
      text: FOOTER_PRESS,
    },
    // {
    //   href:
    //     'https://blog.wowcher.co.uk/?_ga=2.267451022.107186891.1597051109-1566888493.1580121875',
    //   text: 'Blog',
    // },
  ],
  wowcherie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/aboutus`,
      text: FOOTER_ABOUT_US,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/workwithus`,
      text: FOOTER_MERCHANT_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/guides/wowcher-affiliates`,
      text: FOOTER_AFFILIATE_FAQ,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/guides/press-resources`,
      text: FOOTER_PRESS,
    },
    // {
    //   href:
    //     'https://blog.wowcher.co.uk/?_ga=2.267451022.107186891.1597051109-1566888493.1580121875',
    //   text: 'Blog',
    // },
  ],
};

export const NEED_HELP = 'Need Help';
export const FOOTER_REFUNDS_RETURNS = 'Refunds and Returns';
export const FOOTER_CONTACT_US = 'Contact Us';
export const FOOTER_RECALLS = 'Product Recalls';
export const FOOTER_COMPLAINTS = 'Complaints';

// TODO: update this with correct wowcher ie value
export const FOOTER_CUSTOMER_SERVICE_LINKS = {
  livingsocial: [
    {
      href: 'https://help.wowcher.co.uk',
      text: NEED_HELP,
    },
    {
      href: `https://help.wowcher.co.uk/articles/faqs/i-would-like-to-make-a-complaint-livingsocial-uk-and-ireland/653a390d477f6f20ed81b569`,
      text: FOOTER_COMPLAINTS,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/productrecalls`,
      text: FOOTER_RECALLS,
    },
  ],
  livingsocialie: [
    {
      href: 'https://help.wowcher.co.uk',
      text: NEED_HELP,
    },
    {
      href: `https://help.wowcher.co.uk/articles/faqs/i-would-like-to-make-a-complaint-livingsocial-uk-and-ireland/653a390d477f6f20ed81b569`,
      text: FOOTER_COMPLAINTS,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/productrecalls`,
      text: FOOTER_RECALLS,
    },
  ],
  wowcher: [
    {
      href: `https://help.wowcher.co.uk`,
      text: NEED_HELP,
    },
    {
      href: `https://help.wowcher.co.uk/knowledgebase/s/article/Refunds`,
      text: FOOTER_REFUNDS_RETURNS,
    },
    {
      href: `https://help.wowcher.co.uk/articles/faqs/i-would-like-to-make-a-complaint/649c271a42417c2dcb614222`,
      text: FOOTER_COMPLAINTS,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/productrecalls`,
      text: FOOTER_RECALLS,
    },
  ],
  wowcherie: [
    {
      href: `https://help.wowcher.co.uk`,
      text: NEED_HELP,
    },
    {
      href: `https://help.wowcher.co.uk/knowledgebase/s/article/Refunds`,
      text: FOOTER_REFUNDS_RETURNS,
    },
    {
      href: `https://help.wowcher.co.uk/articles/faqs/i-would-like-to-make-a-complaint/649c271a42417c2dcb614222`,
      text: FOOTER_COMPLAINTS,
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/productrecalls`,
      text: FOOTER_RECALLS,
    },
  ],
};

// TODO: update this with correct wowcher ie value
export const MORE_POPOVER_LINKS = {
  livingsocial: [{ href: `https://help.wowcher.co.uk`, text: 'Need Help' }],
  livingsocialie: [{ href: `https://help.wowcher.co.uk`, text: 'Need Help' }],
  wowcher: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/myaccount/refer-a-friend`,
      text: 'Refer A Friend',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/workwithus`,
      text: 'Work With Us',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/student-discount`,
      text: 'Student Discount',
    },
  ],
  wowcherie: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/myaccount/refer-a-friend`,
      text: 'Refer A Friend',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/workwithus`,
      text: 'Work With Us',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/student-discount`,
      text: 'Student Discount',
    },
  ],
};

export const LOGIN_MENU = {
  livingsocial: [
    {
      href: `/myaccount/vouchers`,
      text: 'my vouchers',
    },
    {
      href: `/myaccount/profile`,
      text: 'my details',
    },
  ],
  livingsocialie: [
    {
      href: `/myaccount/vouchers`,
      text: 'my vouchers',
    },
    {
      href: `/myaccount/profile`,
      text: 'my details',
    },
  ],
  wowcher: [
    {
      href: `/myaccount/vouchers`,
      text: 'My Wowchers',
    },
    {
      href: `/myaccount/profile`,
      text: 'My Details',
    },
  ],
  wowcherie: [
    {
      href: `/myaccount/vouchers`,
      text: 'My Wowchers',
    },
    {
      href: `/myaccount/profile`,
      text: 'My Details',
    },
  ],
};

// RAF enabled menu for wowcher only
export const RAF_ENABLED_LOGIN_MENU = [
  ...LOGIN_MENU.wowcher,
  {
    href: `/myaccount/refer-a-friend`,
    text: 'Refer a Friend',
  },
];
export const REGISTER_MENU = {
  livingsocial: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/${LOGIN_URL}`,
      text: 'Login',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/${REGISTER_URL}`,
      text: 'Sign Up',
    },
  ],
  livingsocialie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/${LOGIN_URL}`,
      text: 'Login',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/${REGISTER_URL}`,
      text: 'Sign Up',
    },
  ],
  wowcher: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/${LOGIN_URL}`,
      text: 'Login',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/${REGISTER_URL}`,
      text: 'Sign Up',
    },
  ],
  wowcherie: [
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/${LOGIN_URL}`,
      text: 'Login',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/${REGISTER_URL}`,
      text: 'Sign Up',
    },
  ],
};

export const MOBILE_MENU_MORE_LINKS = {
  livingsocial: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    { href: 'https://marketplace.wowcher.co.uk', text: 'Create a deal' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/aboutus`,
      text: 'About Us',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/faqs`,
      text: 'FAQs',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/privacypolicy`,
      text: 'Privacy & Cookie Policy',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/termsofuse`,
      text: 'Terms of use',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/e-commerceterms`,
      text: 'E-commerce terms',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/page/internetsafety`,
      text: 'Internet Safety',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSUK]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
  ],
  livingsocialie: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    { href: 'https://marketplace.wowcher.co.uk', text: 'Create a deal' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/aboutus`,
      text: 'About Us',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/faqs`,
      text: 'FAQs',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/privacypolicy-ie`,
      text: 'Privacy & Cookie Policy',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/termsofuse-ie`,
      text: 'Terms of use',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/e-commerceterms-ie`,
      text: 'E-commerce terms',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/page/internetsafety`,
      text: 'Internet Safety',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_LSIE]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
  ],
  wowcher: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/workwithus`,
      text: 'Work With Us',
    },
    { href: 'https://marketplace.wowcher.co.uk', text: 'Create a deal' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/aboutus`,
      text: 'About Us',
    },
    {
      href: `https://help.wowcher.co.uk/knowledgebase/s/`,
      text: 'FAQs',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/privacypolicy`,
      text: 'Privacy & Cookie Policy',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/termsofuse`,
      text: 'Terms of use',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/e-commerceterms`,
      text: 'E-commerce terms',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/page/internetsafety`,
      text: 'Internet Safety',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOW]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
  ],
  wowcherie: [
    { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/workwithus`,
      text: 'Work With Us',
    },
    { href: 'https://marketplace.wowcher.co.uk', text: 'Create a deal' },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/aboutus`,
      text: 'About Us',
    },
    {
      href: `https://help.wowcher.co.uk/knowledgebase/s/`,
      text: 'FAQs',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/privacypolicy`,
      text: 'Privacy & Cookie Policy',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/termsofuse`,
      text: 'Terms of use',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/e-commerceterms`,
      text: 'E-commerce terms',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/page/internetsafety`,
      text: 'Internet Safety',
    },
    {
      href: `${LINKS_GLOBAL_WOWCHER[ENV][BRAND_WOWCHERIE]}/giftcard/redeem`,
      text: 'Redeem Gift Card',
    },
  ],
};

// Gifting
export const GIFTING_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/checkout/config/gifting`;

// We only get 2 deals for page > 0
export const ONLY_MAIN_DEAL_EXTRA_SUFFIX = '?pageSize=##NUMBER##';

// We show 9 deals per page so let's make the request based on this number
export const NUMBER_OF_DEALS = 9;
export const DESKTOP_NUMBER_OF_PAGES = 1;
export const DESKTOP_PAGE_SIZE = NUMBER_OF_DEALS * DESKTOP_NUMBER_OF_PAGES;
export const SEARCH_PAGE_SIZE = 12;
export const GUIDE_PAGE_SIZE = 30;
export const DEFAULT_TOTAL_GUIDE_DEALS = 100;

// Get Deals links

export const MAIN_DEALS_DEFAULT_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/product-listing-page-deal/national-deal`;
export const MAIN_DEALS_PREFIX_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1`;
export const PAGE_SIZE_SUFFIX = `?pageSize=${DESKTOP_PAGE_SIZE}`;
export const DEALS_SUFFIX_LINK_PAGE_NUMBER = '&page=##NUMBER##';
export const DEALS_PAGE_PARAMETER = 'page';
export const SCROLLER_CONFIGURATION_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/content/scroller-configuration?brand=##brand##`;
export const NEW_PRODUCTS_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/product-listing-page-deal/##location##/special/new-products`;
export const LINKS_GLOBAL_ONETRUST =
  'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';

export const LINKS_GLOBAL_ONETRUST_AUTOBLOCK = `https://cdn-ukwest.onetrust.com/consent/${process.env.NEXT_PUBLIC_ONETRUST_KEY}/OtAutoBlock.js`;

// TODO: update this with correct wowcher ie value
export const LINKS_GLOBAL_DTM = {
  dev: {
    livingsocial:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-a7f8c350452a-development.min.js',
    livingsocialie:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-a7f8c350452a-development.min.js',
    wowcher:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-9d2eefbcb197-development.min.js',
    wowcherie:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-9d2eefbcb197-development.min.js',
  },
  nxt: {
    livingsocial:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-1ffad00d0545-staging.min.js',
    livingsocialie:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-1ffad00d0545-staging.min.js',
    wowcher:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-80cfe819b208-staging.min.js',
    wowcherie:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-80cfe819b208-staging.min.js',
  },
  prod: {
    livingsocial:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-e85bde02e774.min.js',
    livingsocialie:
      '//assets.adobedtm.com/db068b679b47/23da2dffa1a2/launch-e85bde02e774.min.js',
    wowcher:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-9543b53a1c22.min.js',
    wowcherie:
      '//assets.adobedtm.com/db068b679b47/1c2b442f468f/launch-9543b53a1c22.min.js',
  },
};

export const LINKS_GLOBAL_CWV = {
  dev: {
    livingsocial: '',
    livingsocialie: '',
    wowcher: '',
    wowcherie: '',
  },
  nxt: {
    livingsocial:
      'https://rum.corewebvitals.io/cwv/664615188466391fd4012416.js',
    livingsocialie: '',
    wowcher: 'https://rum.corewebvitals.io/cwv/65ae7a2e4f27fb498cf8a247.js',
    wowcherie: '',
  },
  prod: {
    livingsocial:
      'https://rum.corewebvitals.io/cwv/664615188466391fd4012416.js',
    livingsocialie: '',
    wowcher: 'https://rum.corewebvitals.io/cwv/65ae7a2e4f27fb498cf8a247.js',
    wowcherie: '',
  },
};

export const LINKS_GLOBAL_AWIN = {
  dev: {
    livingsocial: 'https://www.dwin1.com/3925.js',
    livingsocialie: 'https://www.dwin1.com/4319.js ',
    wowcher: 'https://www.dwin1.com/3595.js',
    wowcherie: 'https://www.dwin1.com/3595.js',
  },
  nxt: {
    livingsocial: 'https://www.dwin1.com/3925.js',
    livingsocialie: 'https://www.dwin1.com/4319.js ',
    wowcher: 'https://www.dwin1.com/3595.js',
    wowcherie: 'https://www.dwin1.com/3595.js',
  },
  prod: {
    livingsocial: 'https://www.dwin1.com/3925.js',
    livingsocialie: 'https://www.dwin1.com/4319.js ',
    wowcher: 'https://www.dwin1.com/3595.js',
    wowcherie: 'https://www.dwin1.com/3595.js',
  },
};

export const LINKS_GLOBAL_PAYPAL = {
  dev:
    'ASOxT2nj3kc5OkR2f4O3Mg5Bt88XBGIYIdHqPg1sUORhl-cLpGyLvgW6OocfgOi7qNmUpJzdJEzTsfFr',
  nxt:
    'ASOxT2nj3kc5OkR2f4O3Mg5Bt88XBGIYIdHqPg1sUORhl-cLpGyLvgW6OocfgOi7qNmUpJzdJEzTsfFr',
  prod: 'AR951hC7nvA6w4AR3uK4aC9APN7PU0fBUQiBfDqt0h8CyEryokAB9yHjeaDT',
};

export const AWIN_ADVERTISER_ID = {
  livingsocial: 3_925,
  livingsocialie: 4_319,
  wowcher: 3_595,
  wowcherie: 3_595,
};

// CHECKOUT
// TODO: update this with correct wowcher ie value
export const LINK_ECOMMERCE_TERMS = {
  livingsocial: {
    href: 'https://www.livingsocial.co.uk/page/e-commerceterms',
    text: 'E-Commerce Terms',
  },
  livingsocialie: {
    href: 'https://www.livingsocial.ie/page/e-commerceterms-ie',
    text: 'E-Commerce Terms',
  },
  wowcher: {
    href: 'https://www.wowcher.co.uk/page/e-commerceterms',
    text: 'E-Commerce Terms',
  },
  wowcherie: {
    href: 'https://www.wowcher.co.uk/page/e-commerceterms',
    text: 'E-Commerce Terms',
  },
};

export const LINK_FILTER_API = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/deal`;

export const LINK_NOTFOUNDHIT = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API_TEST
}/v1/notFoundHit`;

// end-point to add general logs to splunk
export const POST_LOGS = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API_TEST
}/v1/log`;

export const CLOUDFLARE_TRACE = 'https://www.cloudflare.com/cdn-cgi/trace';

export const PAYPAL_CREDIT_LINK =
  'https://www.paypal.com/uk/webapps/mpp/paypal-virtual-credit/faq';

export const emailPreferencesWowcherLink = (email) =>
  `https://preferences.wowcher.co.uk/index/v2?email=${email}`;

export const emailPreferencesLSLink = (email) =>
  `https://preferences.livingsocial.co.uk/index?email=${email}`;

// WALLET
export const WALLET_TERMS_OF_USE = '/page/wallettermsofuse';
export const WALLET_CREDIT_INFO = '/page/wallet-credit-more-info';

// VIP T&C
export const LINK_VIP_TERMS = {
  livingsocial:
    'https://www.livingsocial.co.uk/page/vip-terms-and-conditions-ls',
  livingsocialie:
    'https://www.livingsocial.co.uk/page/vip-terms-and-conditions-lsie',
  wowcher: 'https://www.wowcher.co.uk/page/vip-terms-and-conditions',
  wowcherie: 'https://www.wowcher.co.uk/page/vip-terms-and-conditions',
};

// PMV T&C
export const LINK_PMV_TERMS = {
  livingsocial:
    'https://www.livingsocial.co.uk/page/e-commerceterms#protect-my-voucher',
  livingsocialie:
    'https://www.livingsocial.ie/page/e-commerceterms#protect-my-voucher',
  wowcher: 'https://www.wowcher.co.uk/page/e-commerceterms#protect-my-voucher',
  wowcherie:
    'https://www.wowcher.co.uk/page/e-commerceterms#protect-my-voucher',
};
export const LINK_CONTACT_US = `https://api.whatsapp.com/send?phone=447917171746`;

// VVE
export const LINK_VVE_TERMS = {
  livingsocial:
    'https://livingsocial.co.uk/page/e-commerceterms#voucher-validity-extensions',
  livingsocialie:
    'https://www.livingsocial.ie/page/e-commerceterms-ie#voucher-validity-extensions',
  wowcher:
    'https://www.wowcher.co.uk/page/e-commerceterms#voucher-validity-extensions',
  wowcherie:
    'https://www.wowcher.co.uk/page/e-commerceterms#voucher-validity-extensions',
};

// Admin Fee Terms
export const ADMIN_FEE_TERMS = {
  livingsocial: 'https://www.livingsocial.co.uk/page/admin-fee',
  livingsocialie: 'https://www.livingsocial.ie/page/admin-fee-ie',
  wowcher: 'https://www.wowcher.co.uk/page/admin-fee',
  wowcherie: 'https://www.wowcher.co.uk/page/admin-fee',
};

export const REDEEM_IMAGE_TYPES = {
  calendar: 'calendar',
  callToBook: 'call-to-book',
  chooseYourFlight: 'choose-your-flight',
  mysteryTravel: 'mystery-travel',
  standard: 'standard',
  standardTravel: 'standard-travel',
};

export const REDEEM_IMAGES = {
  'living-social': {
    [REDEEM_IMAGE_TYPES.calendar]:
      'https://resources.wowcher.co.uk/assets/img/living-social-redeem-travel-calendar.svg',
    [REDEEM_IMAGE_TYPES.callToBook]:
      'https://resources.wowcher.co.uk/assets/img/living-social-redeem-travel-call-to-book.svg',
    [REDEEM_IMAGE_TYPES.chooseYourFlight]:
      'https://resources.wowcher.co.uk/assets/img/living-social-redeem-travel-cyf.svg',
    [REDEEM_IMAGE_TYPES.mysteryTravel]:
      'https://resources.wowcher.co.uk/assets/img/brand/living-social/redeem/living-social-redeem-mystery-holiday.png',
    [REDEEM_IMAGE_TYPES.standard]:
      'https://resources.wowcher.co.uk/assets/img/how-to-redeem-livingsocial.svg',
    [REDEEM_IMAGE_TYPES.standardTravel]:
      'https://resources.wowcher.co.uk/assets/img/living-social-redeem-travel-standard.svg',
  },
  wowcher: {
    [REDEEM_IMAGE_TYPES.calendar]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-calendar.svg',
    [REDEEM_IMAGE_TYPES.callToBook]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-call-to-book.svg',
    [REDEEM_IMAGE_TYPES.chooseYourFlight]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-cyf.svg',
    [REDEEM_IMAGE_TYPES.mysteryTravel]:
      'https://resources.wowcher.co.uk/assets/img/brand/wowcher/wowcher-redeem-mystery-holiday.png',
    [REDEEM_IMAGE_TYPES.standard]:
      'https://resources.wowcher.co.uk/assets/img/how-to-redeem-wowcher.svg',
    [REDEEM_IMAGE_TYPES.standardTravel]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-standard.svg',
  },
  wowcherie: {
    [REDEEM_IMAGE_TYPES.calendar]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-calendar.svg',
    [REDEEM_IMAGE_TYPES.callToBook]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-call-to-book.svg',
    [REDEEM_IMAGE_TYPES.chooseYourFlight]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-cyf.svg',
    [REDEEM_IMAGE_TYPES.mysteryTravel]:
      'https://resources.wowcher.co.uk/assets/img/brand/wowcher/wowcher-redeem-mystery-holiday.png',
    [REDEEM_IMAGE_TYPES.standard]:
      'https://resources.wowcher.co.uk/assets/img/how-to-redeem-wowcher.svg',
    [REDEEM_IMAGE_TYPES.standardTravel]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-standard.svg',
  },
};

// APP Links

// TODO: update this with correct wowcher ie value
export const BRANCH_LINKS_PROD = {
  livingsocial: 'https://living-social.app.link/b0Aoqj2yjqb',
  livingsocialie: 'https://living-social.app.link/b0Aoqj2yjqb',
  wowcher: 'https://wowcher.app.link/buzi0hczjqb',
  wowcherie: 'https://wowcher.app.link/buzi0hczjqb',
};

// TODO: update this with correct wowcher ie value
export const BRANCH_LINKS_DEV = {
  livingsocial: 'https://living-social.test-app.link/oeUYHrOyjqb',
  livingsocialie: 'https://living-social.test-app.link/oeUYHrOyjqb',
  wowcher: 'https://wowcher.test-app.link/G9OeOIoYhqb',
  wowcherie: 'https://wowcher.test-app.link/G9OeOIoYhqb',
};
